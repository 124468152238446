<template>
    <div class="fixed-tab-nav">
        <loader v-if="isLoading || paymentLoading" :text="offlineSigningText"/>

        <div class="st-wizard-tabs pb-6" :class="{ fixed: scrollPosition > 10 }">
            <b-nav tabs justified align="start" class="st-wizard-tabs-nav" v-b-scrollspy="260">
                <b-nav-item key="pre" class="nav-item-initial" disabled>
                    <div class="d-flex justify-content-center icon-arrow-right">
                        <i class="far fa-file-alt"></i>
                    </div>
                </b-nav-item>
                <b-nav-item
                    :href="tab.id"
                    v-on:click.prevent="goToSection(tab.ref, $event)"
                    v-for="tab in tabs"
                    :key="tab.index"
                    :disabled="tab.disabled"
                    v-show="tab.show"
                >
                    <div class="d-flex justify-content-center w-100">
                        <i :class="tab.icon"></i>
                        <span class="nav-item-text text-uppercase ml-4">{{
                                $t(tab.name)
                            }}</span>
                    </div>
                </b-nav-item>
            </b-nav>
            <st-button
                :disabled="!selectedApplicationType"
                variant="light"
                :spinner="isLoadingSaveDraft"
                :callback="() => saveAsDraft(true, true)"
            >
                <i class="fa fa-save"></i>
                {{ $t('APPLICATION.FORM.SAVE_AS_DRAFT_BUTTON') }}
            </st-button>
        </div>
        <div class="navbar-scroll">
            <application-details-form
                ref="applicationDetailsForm"
                @change="changeApplicationType"
            />

            <generate-form
                v-if="formSchema"
                :data="formSchema"
                :value="formData"
                ref="generateForm"
                @modelUpdated="updateFormData"
            ></generate-form>

            <territorial-atlas
                v-if="formSchema"
                :realEstates="realEstatesList"
                :isNew="true"
                ref="territorialAtlas"
            ></territorial-atlas>

            <signature-details
                v-if="formSchema"
                @updateSignatureType="updateSignatureType"
                @updateCertSignPackage="updateCertSignPackages"
                @downloadFiles="downloadPdfFile"
                @validatePackage="validatePackage"
                ref="signatureDetails"
            ></signature-details>

            <application-summary
                v-if="formSchema"
                :formModels="formData"
                :signDetails="signDetails"
                :taxCategoryName="taxCategoryName"
                ref="applicationSummary"
            ></application-summary>

            <div class="d-flex align-items-center justify-content-center mt-6" v-if="formSchema">
                <st-button :callback="doPay" v-if="!isPaid" :disabled="disablePayment">
                    {{ $t('APPLICATION.FORM.PAY_BUTTON') }}
                </st-button>
                <st-button
                    v-else
                    :callback="doSign"
                >{{ $t('APPLICATION.FORM.SIGN_BUTTON') }}
                </st-button>
            </div>

        </div>
        <confirm-sign-modal
            :applicationId="applicationId"
            ref="confirm-sign-document-modal"
            @signValidated="signValidated"
        />
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import GenerateForm from '@/modules/forms/components/generate-form/GenerateForm.vue';
import Message from '@/shared/message/message';
import { ApplicationModel } from '@/modules/applications/models/application-model';
import Vue from 'vue';
import TaxesStoreObserverMixin from '@/modules/applications/store/taxes-store-observer-mixin';
import { RE_ENROLL_ERRORS } from '@/modules/applications/utils/constants';
import Errors from '@/shared/error/errors';
import { axiosInlineDownload } from '@/modules/applications/utils/axios-inline-downloader';

const {fields} = ApplicationModel;

export default {
    name: 'ApplicationsForm',
    mixins: [TaxesStoreObserverMixin],
    components: {
        GenerateForm,
        ApplicationDetailsForm: () => import('@/modules/applications/components/ApplicationDetailsForm'),
        TerritorialAtlas: () => import('@/modules/applications/components/territorial-atlas/TerritorialAtlasCard'),
        SignatureDetails: () => import('@/modules/applications/components/signature/SignatureDetails'),
        ApplicationSummary: () => import('@/modules/applications/components/summary/ApplicationSummary'),
        ConfirmSignModal: () => import('@/modules/applications/components/signature/ConfirmSignModal'),
    },
    props: {
        id: String,
    },
    data() {
        return {
            formSchema: null,
            formData: {},
            taxDetails: [],
            appDetails: {},
            selectedApplicationType: null,
            signDetails: {
                isOnline: true,
                package: null,
            },
            paymentStatus: 'successful',
            identificationFilesUploaded: false,
            showConfirmSignModal: false,
            taxCategoryName: '',
            activeMenu: 'applicationDetailsForm',
            scrollPosition: null,
            needPackageValidation: true,
        };
    },
    computed: {
        ...mapGetters({
            record: 'applications/form/record',
            loading: 'shared/loading',
            currentUser: 'auth/currentUser',
            downloadToken: 'applications/form/fileToken',
            uploadToken: 'applications/form/uploadAccessToken',
            signingStatus: 'applications/form/signingStatus',
        }),
        applicationId() {
            return this.record?.id || '';
        },
        isPaid() {
            return !!this.record?.is_paid;
        },
        backLinkComputed() {
            return this.id ? {name: 'applications'} : '';
        },
        titleComputed() {
            if (!this.id) return this.$t('APPLICATION.FORM.TITLE');
            const uid = this.record?.identification_number;
            const applicationTypeName = this.record?.application_type_name;
            return `${uid} - ${applicationTypeName}`;
        },
        isLoading() {
            return (
                this.loading['applications/find'] ||
                this.loading['applications/pay'] ||
                this.isLoadingSaveDraft ||
                this.loading['applications/initializeOfflineSign']
            );
        },
        isLoadingSaveDraft() {
            return (
                this.loading['applications/create'] ||
                this.loading['applications/update']
            );
        },
        offlineSigningText() {
            return this.loading['applications/initializeOfflineSign'] ? this.$t('APPLICATION.NOTIFIERS.GENERATE_OFFLINE_PACKAGE') : '';
        },
        showTaxesSection() {
            return this.taxDetails.length;
        },
        tabs() {
            return [
                {
                    index: 0,
                    name: 'APPLICATION.TABS.NEW_APPLICATION',
                    ref: 'applicationDetailsForm',
                    id: '#application-details-target',
                    icon: 'fas fa-edit',
                    show: true,
                },
                {
                    index: 1,
                    name: 'APPLICATION.TABS.BENEFICIARY',
                    ref: 'beneficiaryList',
                    id: '#beneficiary-target',
                    disabled: !this.selectedApplicationType,
                    icon: 'fas fa-users',
                    show: true,
                },
                {
                    index: 2,
                    name: 'APPLICATION.TABS.REAL_ESTATE',
                    ref: 'realEstateList',
                    id: '#real-estate-target',
                    disabled: !this.selectedApplicationType,
                    icon: 'fas fa-home',
                    show: true,
                },
                {
                    index: 3,
                    name: 'APPLICATION.TABS.SIGN',
                    ref: 'signatureDetails',
                    id: '#signature-details-target',
                    disabled: !this.selectedApplicationType,
                    icon: 'fas fa-pencil-alt',
                    show: true,
                },
                {
                    index: 4,
                    name: 'APPLICATION.TABS.SUMMARY',
                    ref: 'applicationSummary',
                    id: '#summary',
                    disabled: !this.selectedApplicationType,
                    icon: 'fas fa-check-circle',
                    show: true,
                },
            ];
        },
        paymentLoading() {
            return this.paymentStatus !== 'successful' && this.paymentStatus !== 'failed';
        },
        disablePayment() {
            if (this.record) {
                return !this.signDetails.isOnline && !this.record.sign_details.isOnline && !this.record.is_signed;
            }

            return false;
        },
        realEstatesList() {
            if (!this.formData?.real_estate_target?.length) return [];
            return this.formData.real_estate_target.filter((el) => el?.coordinates?.length);
        }
    },
    methods: {
        ...mapActions({
            doCreate: 'applications/form/create',
            doUpdate: 'applications/form/update',
            calculateTaxes: 'applications/form/calculateTaxes',
            doFindApplicationType: 'applications/form/findApplicationType',
            doFindForm: 'applications/form/findForm',
            doUpload: 'applications/form/upload',
            doPayApplication: 'applications/form/pay',
            initializeOfflineSigning: 'applications/form/initializeOfflineSigning',
            doDownload: 'applications/form/downloadDocument',
            sendApplication: 'applications/form/finalizeApplication',
            doEnrollment: 'applications/form/enrollPerson',
            signDoc: 'applications/form/signDocumentCitizen',
            initApplicationState: 'applications/form/initFormState',
            doFindTaxCategory: 'applications/form/findTaxCategory',
            doFind: 'applications/form/find',
            doClear: 'applications/form/clear',
            updateApplicationValidation: 'applications/form/validation',
            doSetSelectedApplicationType: 'applications/form/doSetSelectedApplicationType',
            updateOfflineSignDownloadStatus: 'applications/form/updateOfflineSignDownloadStatus',
        }),
        updateFormData(data, key) {
            Vue.set(this.formData, key, data);
        },
        changeApplicationType({application_type_id}) {
            this.formSchema = null;
            this.formData = {};
            this.taxDetails = {};
            this.appDetails = {};
            this.formData.application_type_id = application_type_id;
            this.formData.application_type_name = fields.application_type_id.options.find(
                (el) => el.value === application_type_id
            )?.text;
            this.doSetSelectedApplicationType(application_type_id);
            if (!application_type_id) {
                this.selectedApplicationType = null;
                return;
            }
            this.selectedApplicationType = application_type_id;
            this.doFindApplicationType(application_type_id).then(
                (applicationTypeData) => {
                    const {form_citizen_id, tax_category_id} = applicationTypeData;
                    if (tax_category_id) {
                        this.doFindTaxCategory(tax_category_id).then((data) => {
                            this.taxCategoryName = data.name;
                        });
                    }
                    if (form_citizen_id) {
                        this.doFindForm(form_citizen_id).then((formData) => {
                            this.formSchema = formData.schema;
                        });
                    } else {
                        this.formSchema = null;
                        Message.error('APPLICATION.FORM.NO_TEMPLATE');
                    }
                }
            );
        },
        async generateData(asDraft) {
            const validateDetailsForm = await this.$refs.applicationDetailsForm.fv.validate();

            if (validateDetailsForm === 'Valid') {
                try {
                    const genFormData = await this.$refs.generateForm.getData(asDraft);
                    const applicationDetailForm = this.$refs.applicationDetailsForm;

                    const {options, form} = genFormData;
                    let formLables = {};

                    for (let item in options) {
                        const opts = options[item].options;
                        if (opts?.length && typeof opts[0] === 'object') {
                            const selectedOption = opts.find(opt => opt.value === form[item]);
                            if (selectedOption) {
                                formLables[`${item}_label`] = selectedOption.label;
                            }
                        }
                    }

                    const formWithOptions = {
                        form: {
                            ...formLables,
                            ...form,
                            options,
                        }
                    };

                    const formData = {
                        ...formWithOptions,
                        ...this.$refs.applicationDetailsForm.model,
                        application_type_id: applicationDetailForm.model.application_type_id,
                        application_type_name: applicationDetailForm.fields.application_type_id.options.find(
                            (el) => el.value === applicationDetailForm.model.application_type_id).text,
                    };
                    return formData;
                } catch (error) {
                    this.updateApplicationValidation(false);
                }
            }
        },
        async saveAsDraft(asDraft, redirect) {
            const data = await this.generateData(asDraft);
            if (!data) return false;
            data.status = 'draft';
            data.sign_details = this.signDetails;
            data.is_signed = false;

            let attachedFiles;
            let needUpload = false;
            if (data.form?.attachments?.length) {
                const formData = new FormData();
                for (let i = 0; i < data.form?.attachments?.length; i++) {
                    const file = data.form.attachments[i];
                    formData.append('files', file);
                    needUpload = true;
                }
                attachedFiles = formData;
                delete data.form.attachments;
            }

            let application = null;
            if (this.record?.id) {
                // TODO: Remove, it's not used anymore
                application = await this.doUpdate({
                    id: this.record.id,
                    values: data,
                    loadStaff: false
                });
            } else {
                application = await this.doCreate(data);
                if (needUpload && application?.uploadAccessToken) {
                    const {data, uploadAccessToken} = application;
                    const uploadedFiles = await this.doUpload({
                        files: attachedFiles,
                        uploadAccessToken,
                    });
                    const identificationFiles = uploadedFiles.map((el) => ({
                        file_id: el.id,
                        filename: el.initialFilename,
                        created_date: el.created_date,
                        file_type: 'input',
                        created_by: `${this.currentUser.first_name} ${this.currentUser.last_name}`
                    }));
                    application = await this.doUpdate({
                        id: data.id,
                        values: {
                            ...data,
                            identification_files: identificationFiles,
                        },
                    });
                }
            }

            Message.success('APPLICATION.NOTIFIERS.DRAFT_SAVED_SUCCESS');

            if (redirect) {
                this.$router.push({
                    name: 'applications'
                });
            } else {
                return application?.data ? application.data : application;
            }
        },
        goToSection(href, e) {
            e.stopPropagation();
            let $el;
            if (this.$refs[href]) {
                $el = this.$refs[href].$el;
            } else if (this.$refs.generateForm.$refs[href]) {
                $el = this.$refs.generateForm.$refs[href][0].$el;
            }
            if ($el) {
                const elementPosition = $el.offsetTop;
                window.scrollTo({
                    top: elementPosition,
                    behavior: 'smooth',
                });
            }
        },
        updateSignatureType(isOnline) {
            this.signDetails = {
                isOnline,
            };
        },
        updateCertSignPackages(item) {
            this.signDetails = {
                ...this.signDetails,
                package: item,
            };
        },
        async doPay() {
            //need to check if the user has enough signatures
            if (this.signDetails.isOnline && this.needPackageValidation && !this.signDetails.package) {
                Message.error('APPLICATION.NOTIFIERS.CHOOSE_SIGN_PACKAGE');
                return;
            }

            if (!this.signDetails.isOnline) {
                //check if signed
                if (!this.record || (this.record && !this.record.is_signed)) {
                    Message.error('APPLICATION.NOTIFIERS.OFFLINE_SIGN_FAILED');
                    return;
                }
            }

            //save application if it is signed online
            //if it is signed offline, the application is saved as draft in download documents step
            if (this.signDetails.isOnline) {
                let response = await this.saveAsDraft(false);

                if (!response) {
                    return false;
                }
            }

            //change amount after everything is done
            const payload = {
                id: this.record.id,
                data: {
                    amount: 1,
                    currency: this.$t('GENERAL.CURRENCY_LABEL'),
                    summary: [],
                }
            }

            if (this.signDetails.isOnline && this.signDetails.package) {
                payload.data.summary.push({
                    id: this.signDetails.package.id,
                    name: this.signDetails.package.name,
                    type: 'CERTSIGN',
                    amount: this.signDetails.package.price_including_vat ?? 0,
                    signatures: this.signDetails.package.quantity ?? 0
                });
            }

            this.doPayApplication(payload).then((data) => {
                //what happens if there is no payment url ?
                if (data?.bt_payment_form_url) {
                    window.location.replace(data.bt_payment_form_url);
                }
            })
        },
        async doSign() {
            let enrollmentRequest = {
                user_id: this.currentUser.user_id,
                email: this.currentUser.email,
                first_name: this.currentUser.first_name,
                last_name: this.currentUser.last_name,
                id_card_number: this.currentUser.id_card_number,
                id_card_serial: this.currentUser.id_card_serial,
                id_card_expiration_date: this.currentUser.id_card_expiration_date,
                id_card_issuer: this.currentUser.id_card_issuer,
                cnp: this.currentUser.personal_numeric_code,
                phone_number: this.currentUser.phone_number,
                county: this.currentUser.county,
                type: 0,
            };

            const showModalInnerMethod = () => {
                this.showConfirmSignModal = true;
                this.$nextTick(() => {
                    this.$refs['confirm-sign-document-modal'].show();
                });
            };

            if (this.currentUser.enrolled) {
                try {
                    await this.signDoc(this.record.id);
                    showModalInnerMethod();
                } catch (err) {
                    if (RE_ENROLL_ERRORS.includes(err)) {
                        await this.doEnrollment({...enrollmentRequest, force: true})
                        await this.signDoc(this.record.id);
                        showModalInnerMethod();
                        return;
                    }

                    Errors.handle(err);
                }
            }

            await this.doEnrollment(enrollmentRequest);
            await this.signDoc(this.record.id);
            showModalInnerMethod();
        },
        async downloadPdfFile() {
            //save application
            const application = await this.saveAsDraft(false);
            if (!application) return false;
            let filesLocation = 'files';

            if (this.identificationFilesUploaded) {
                filesLocation += '&filesLocations=identification_files';
            }

            const initializeSign = await this.initializeOfflineSigning({
                id: application.id,
                filesLocation
            });

            //offline sign available
            if (initializeSign?.offlineSign?.files) {
                this.updateOfflineSignDownloadStatus(true);
                const lastFile = initializeSign.offlineSign.files[initializeSign.offlineSign.files.length - 1];
                await axiosInlineDownload({
                    url: `${window.VUE_APP_API_URL}/files/${lastFile.file_id}?token=${this.downloadToken}`,
                    label: lastFile.filename
                });
            }
        },
        signValidated() {
            this.sendApplication(this.record.id).then((data) => {
                this.$router.push({
                    name: 'applicationConfirmation',
                    params: {
                        id: data.identification_number
                    }
                });
            });
        },
        updateScroll() {
            this.scrollPosition = window.scrollY;
        },
        validatePackage(need) {
            this.needPackageValidation = need;
        },
    },
    mounted() {
        window.addEventListener('scroll', this.updateScroll);
    },
    async created() {
        this.initApplicationState();
        this.formSchema = null;
        this.formData = {};
        this.taxDetails = {};
        this.appDetails = {};
    },
    destroy() {
        window.removeEventListener('scroll', this.updateScroll);
    },
};
</script>
<style scoped>
@import '~quill/dist/quill.snow.css';
</style>
